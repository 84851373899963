import axios from 'axios';
import { handleErrorFromRequest } from '@services/handleErrorService.js';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export async function exportFile(path) {
  try {
    const res = await axios.get(path, { responseType: 'blob' });

    const blob = new Blob([res.data], {
      type: res.headers['content-type'],
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const contentDisposition = res.headers['content-disposition'];

    const splittedFilename = contentDisposition.split('filename=')[1].split(';')[0];
    const filename = splittedFilename.replace(/"/g, '"').replace(/['"]+/g, '');

    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function exportFileByEmail(path) {
  try {
    return await axios.get(path, { headers: { Accept: 'application/json' } });
  } catch (error) {
    handleErrorFromRequest(error);
  }
}
